import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import FallbackPage from './FallbackPage';
import { ASSET_NAME } from './LandingPage.duck';
// Import local images if not using public folder

let containerName = 'heroprd';
const url = process.env.REACT_APP_MARKETPLACE_ROOT_URL;
if(url.includes('localhost') || url.includes('dev') || url.includes('test')) {
  containerName = 'herodevtest';
}

const hero1 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group01-Image01.jpg`;
const hero2 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group01-Image02.jpg`;
const hero3 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group01-Image03.jpg`;
const hero4 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group01-Image04.jpg`;
const hero5 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group02-Image01.jpg`;
const hero6 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group02-Image02.jpg`;
const hero7 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group02-Image03.jpg`;
const hero8 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group02-Image04.jpg`;
const hero10 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group03-Image01.jpg`;
const hero11 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group03-Image02.jpg`;
const hero12 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group03-Image03.jpg`;
const hero13 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group03-Image04.jpg`;
const hero14 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group04-Image01.jpg`;
const hero15 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group04-Image02.jpg`;
const hero16 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group04-Image03.jpg`;
const hero18 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group04-Image04.jpg`;
const hero19 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group05-Image01.jpg`;
const hero20 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group05-Image02.jpg`;
const hero21 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group05-Image03.jpg`;
const hero23 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group05-Image04.jpg`;
const hero24 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group06-Image01.jpg`;
const hero25 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group06-Image02.jpg`;
const hero27 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group06-Image03.jpg`;
const hero30 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group06-Image04.jpg`;
const hero31 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group07-Image01.jpg`;
const hero33 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group07-Image02.jpg`;
const hero34 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group07-Image03.jpg`;
const hero35 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group07-Image04.jpg`;
const hero36 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group07-Image05.jpg`;



const Our = 'https://eeventstorage.blob.core.windows.net/oursection/Our.png';
const Wedding = 'https://eeventstorage.blob.core.windows.net/oursection/wedding.svg';
const Marriage = 'https://eeventstorage.blob.core.windows.net/oursection/Marriage.svg';
const WeddingReception = 'https://eeventstorage.blob.core.windows.net/oursection/WeddingReception.svg';
const Birthday = 'https://eeventstorage.blob.core.windows.net/oursection/Birthday.svg';
const Graduation = 'https://eeventstorage.blob.core.windows.net/oursection/Graduation.svg';
const BridalShower = 'https://eeventstorage.blob.core.windows.net/oursection/BridalShower.svg';
const BabyShower = 'https://eeventstorage.blob.core.windows.net/oursection/BabyShower.svg';
const Anniversary = 'https://eeventstorage.blob.core.windows.net/oursection/Anniversary.svg';
const Engagement = 'https://eeventstorage.blob.core.windows.net/oursection/Engagement.svg';
const Retirement = 'https://eeventstorage.blob.core.windows.net/oursection/Retirement.svg';
const HouseWarming = 'https://eeventstorage.blob.core.windows.net/oursection/HouseWarming.svg';
const CocktailParty = 'https://eeventstorage.blob.core.windows.net/oursection/CocktailParty.svg';
const CorporateEvent = 'https://eeventstorage.blob.core.windows.net/oursection/CorporateEvent.svg';
const Christmas = 'https://eeventstorage.blob.core.windows.net/oursection/Christmas.svg';
const Religious = 'https://eeventstorage.blob.core.windows.net/oursection/Religious.svg';
const BachelorParty = 'https://eeventstorage.blob.core.windows.net/oursection/BachelorParty.svg';
const BacheloretteParty = 'https://eeventstorage.blob.core.windows.net/oursection/BacheloretteParty.svg';
const NewYear = 'https://eeventstorage.blob.core.windows.net/oursection/NewYear.svg';
const Halloween = 'https://eeventstorage.blob.core.windows.net/oursection/Halloween.svg';
const Reunion = 'https://eeventstorage.blob.core.windows.net/oursection/Reunion.svg';
const Formals = 'https://eeventstorage.blob.core.windows.net/oursection/Formals.svg';
const HensNight = 'https://eeventstorage.blob.core.windows.net/oursection/HensNight.svg';
const BucksNight = 'https://eeventstorage.blob.core.windows.net/oursection/BucksNight.svg';
const NamingCeremony = 'https://eeventstorage.blob.core.windows.net/oursection/NamingCeremony.svg';
const CommitmentCeremony = 'https://eeventstorage.blob.core.windows.net/oursection/CommitmentCeremony.svg';
const Photoshoot = 'https://eeventstorage.blob.core.windows.net/oursection/Photoshoot.svg';
const TradeShow = 'https://eeventstorage.blob.core.windows.net/oursection/TradeShow.svg';
const CharityEvent = 'https://eeventstorage.blob.core.windows.net/oursection/CharityEvent.svg';
const VIPReception = 'https://eeventstorage.blob.core.windows.net/oursection/VIPReception.svg';
const PrivateDinner = 'https://eeventstorage.blob.core.windows.net/oursection/PrivateDinner.svg';
const TeamBuilding = 'https://eeventstorage.blob.core.windows.net/oursection/TeamBuilding.svg';
const FamilyFunDay = 'https://eeventstorage.blob.core.windows.net/oursection/FamilyFunDay.svg';
const HennaParty = 'https://eeventstorage.blob.core.windows.net/oursection/HennaParty.svg';
const Festival = 'https://eeventstorage.blob.core.windows.net/oursection/Festival.svg';
const JustGetTogether = 'https://eeventstorage.blob.core.windows.net/oursection/JustGetTogether.svg';
const Highlight = 'https://eeventstorage.blob.core.windows.net/oursection/highlight.png';
const Makeup = 'https://eeventstorage.blob.core.windows.net/oursection/Makeup-and-Hair-Artists.jpg';
const Photographers = 'https://eeventstorage.blob.core.windows.net/oursection/Photographers-Videographers.jpg';
const Cook = 'https://eeventstorage.blob.core.windows.net/oursection/cook-chef.jpg';
const Cakes = 'https://eeventstorage.blob.core.windows.net/oursection/cakes.jpg';
const HennaArtists = 'https://eeventstorage.blob.core.windows.net/oursection/henna-artists.jpg';
const Venue = 'https://eeventstorage.blob.core.windows.net/oursection/venue.jpg';
const Decorators = 'https://eeventstorage.blob.core.windows.net/oursection/decorators.jpg';
const Catering = 'https://eeventstorage.blob.core.windows.net/oursection/catering.jpg';
const EventPlanner = 'https://eeventstorage.blob.core.windows.net/oursection/eventplanner.jpg';
const Entertainer = 'https://eeventstorage.blob.core.windows.net/oursection/entertainer.jpg';
const Hospitality = 'https://eeventstorage.blob.core.windows.net/oursection/hospitality.jpg';
const Celebrant = 'https://eeventstorage.blob.core.windows.net/oursection/celebrant.jpg';
const Transportation = 'https://eeventstorage.blob.core.windows.net/oursection/transportation.jpg';
const PartyProps = 'https://eeventstorage.blob.core.windows.net/oursection/partyprops.jpg';


const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

export const LandingPageComponent = props => {
  const { pageAssetsData, inProgress, error, listingsLocationCount } = props;
  const [loading, setLoading] = useState(true);

  // List of images (can be from public folder or imported)
  const imageUrls = [
    hero1,  // If imported, otherwise: '/assets/hero1.jpg' for public folder
    hero2,
    hero3,
    hero4,
    hero5,
    hero6,
    hero7,
    hero8,
    hero10,
    hero11,
    hero12,
    hero13,
    hero14,
    hero15,
    hero16,
    hero18,
    hero19,
    hero20,
    hero21,
    hero23,
    hero24,
    hero25,
    hero27,
    hero30,
    hero31,
    hero33,
    hero34,
    hero35,
    hero36,
    Our, Wedding, Marriage, WeddingReception, Birthday, Graduation, BridalShower, BabyShower, Anniversary, Engagement, Retirement, HouseWarming, CocktailParty, CorporateEvent, Christmas, Religious, BachelorParty, BacheloretteParty, NewYear, Halloween, Reunion, Formals, HensNight, BucksNight, NamingCeremony, CommitmentCeremony, Photoshoot, TradeShow, CharityEvent, VIPReception, PrivateDinner, TeamBuilding, FamilyFunDay, HennaParty, Festival, JustGetTogether, Highlight, Makeup, Photographers, Cook, Cakes, HennaArtists, Venue, Decorators, Catering, EventPlanner, Entertainer, Hospitality, Celebrant, Transportation, PartyProps
  ];

  // Function to preload images
  const preloadImages = (imageUrls) => {
    const promises = imageUrls.map((url) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = resolve;
        img.onerror = reject;
      });
    });
    return Promise.all(promises);
  };

  useEffect(() => {
    // Preload images and hide skeleton once all images are loaded
    preloadImages(imageUrls).then(() => {
    });
  }, []);
  return (
    <div>
      <PageBuilder
        pageAssetsData={pageAssetsData?.[camelize(ASSET_NAME)]?.data}
        inProgress={inProgress}
        error={error}
        fallbackPage={<FallbackPage error={error} />}
        listingsLocationCount={listingsLocationCount}
      />
    </div>
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  averageRatingsListings: object,
  bookingsCountListings: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};

  const { listingsLocationCount } = state.LandingPage;

  return { pageAssetsData, inProgress, error, listingsLocationCount };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(connect(mapStateToProps))(LandingPageComponent);

export default LandingPage;
